import { createStore } from "solid-js/store";

import { vstack, hstack } from "#style/patterns";
import { CsrfForm } from "#root/components/CsrfForm";
import { InputField } from "#root/components/InputField";
import { Modal } from "#root/components/Modal";
import { Button } from "#root/components/Button";
import { RatingField } from "#root/components/RatingField";
import { useTranslation } from "#root/domain/i18n";

type FeedbackFormState = {
  rate: number;
  comment: string;
};

export interface FeedbackModalProps {
  onSubmit: (data: FeedbackFormState) => void;
  onDismiss: () => void;
  isSubmitting: boolean;
  isSuccess: boolean;
}

export function FeedbackModal(props: FeedbackModalProps) {
  const { t } = useTranslation();
  const [formState, setFormState] = createStore<FeedbackFormState>({
    rate: 0,
    comment: "",
  });

  function onSubmit(event: SubmitEvent) {
    event.preventDefault();
    props.onSubmit(formState);
  }

  return (
    <Modal.Root
      open
      header={<Modal.Header title={t("session.feedback.title")} />}
      footer={
        <Modal.Footer
          class={hstack({
            gap: "4",
            justifyContent: "flex-end",
          })}
        >
          <Button
            tag="button"
            type="submit"
            form="feedbackForm"
            color="yellow"
            isLoading={props.isSubmitting}
          >
            {t("session.feedback.send")}
          </Button>
          <Button
            tag="button"
            color="darkGray"
            status="secondary"
            isLoading={props.isSubmitting}
            onClick={props.isSubmitting ? undefined : props.onDismiss}
          >
            {t("session.feedback.close")}
          </Button>
        </Modal.Footer>
      }
      onBackdropClicked={props.isSubmitting ? undefined : props.onDismiss}
    >
      <Modal.Content>
        <CsrfForm
          id="feedbackForm"
          class={vstack({ w: "full", gap: "4" })}
          onSubmit={onSubmit}
        >
          <RatingField
            name="rate"
            label={t("session.feedback.rateFieldLabel")}
            onChange={(value) => setFormState("rate", value)}
            value={formState.rate}
          />
          <InputField
            name="comment"
            type="text"
            optional
            multiline
            label={t("session.feedback.commentFieldLabel")}
            autocomplete={false}
            onChange={(value) => setFormState("comment", value)}
            value={formState.comment}
          />
        </CsrfForm>
      </Modal.Content>
    </Modal.Root>
  );
}
