import { For, Show, Switch, Match, createEffect, createSignal } from "solid-js";
import { useParams } from "@solidjs/router";
import { useQueryClient } from "@tanstack/solid-query";

import { css } from "#style/css";
import { Box, VStack, HStack, Flex, styled } from "#style/jsx";
import Circle from "#lucide-solid/icons/circle";

import { config } from "#root/config";
import {
  useSession,
  useSessionQuestions,
  useSessionUpdates,
} from "#root/domain/session";
import { InputField } from "#root/components/InputField";
import { AppPageContainer } from "#root/components/AppPageContainer";
import { Typography } from "#root/components/Typography";
import { Button } from "#root/components/Button";
import { type Question, type Session } from "#root/module/api";
import { type SocketStatus, useQuestionActions } from "#root/domain/session";
import { useTranslation } from "#root/domain/i18n";
import { getTimeAgo } from "#root/module/date";
import { useAuthenticatedUser } from "#root/domain/user";
import {
  SESSIONS_KEY,
  SESSION_KEY,
  SESSION_QUESTIONS_KEY,
} from "#root/domain/cacheKeys";
import { FeedbackModal } from "#root/views/FeedbackModal";

export default function Session() {
  const params = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const sessionId = () => params.id;

  const { session, nextQuestion, clearQuestion, endSession, rateSession } =
    useSession(sessionId);
  const questions = useSessionQuestions(sessionId);
  const user = useAuthenticatedUser();
  const sessionUpdates = useSessionUpdates(sessionId, {
    needAuth: true,
  });
  const [feedbackDismissed, setFeedbackDismissed] = createSignal(false);

  const [getSocketStatus, setSocketStatus] = createSignal<{ twitch: boolean }>({
    twitch: false,
  });

  createEffect(() => {
    if (!sessionUpdates.isConnected()) return;

    sessionUpdates.pushEvent("get:status", {}, (msg) =>
      setSocketStatus(msg.data),
    );

    sessionUpdates.addEventListener("status:twitch:update", ({ data }) =>
      setSocketStatus((status) => ({
        ...status,
        twitch: data.is_connected,
      })),
    );

    sessionUpdates.addEventListener("status:twitch:update", ({ data }) =>
      setSocketStatus((status) => ({
        ...status,
        twitch: data.is_connected,
      })),
    );

    sessionUpdates.addEventListener("question:new", ({ data }) => {
      queryClient.setQueryData(
        [SESSION_QUESTIONS_KEY, sessionId()],
        (oldData: Question[] | undefined) => {
          if (oldData) {
            return [...oldData, data];
          }
        },
      );
    });

    sessionUpdates.addEventListener("session:terminated", ({ data }) => {
      queryClient.invalidateQueries({ queryKey: [SESSION_KEY, data.id] });
      queryClient.invalidateQueries({
        queryKey: [SESSION_QUESTIONS_KEY, data.id],
      });
      queryClient.invalidateQueries({ queryKey: [SESSIONS_KEY] });
    });
  });

  return (
    <AppPageContainer>
      <Show when={session.data}>
        {(sessionData) => (
          <>
            <SessionHeader
              status={getSocketStatus()}
              session={sessionData()}
              userId={user.id}
              onEndSession={() => endSession.mutate()}
            />

            <Box mx="-10" mb="10">
              <ActiveQuestionController
                session={sessionData()}
                questions={questions.data}
                onNextQuestion={nextQuestion}
                onClearQuestion={clearQuestion}
              />
            </Box>

            <SessionBacklog
              sessionId={sessionData().id}
              isSessionActive={!sessionData().doneAt}
              questions={questions.data.list}
            />

            <Show
              when={
                Boolean(sessionData().doneAt) &&
                !sessionData().ratingSubmitted &&
                !feedbackDismissed()
              }
            >
              <FeedbackModal
                onDismiss={() => {
                  rateSession.mutate(null);
                  setFeedbackDismissed(true);
                }}
                onSubmit={(data) => {
                  rateSession.mutateAsync(data);
                  setFeedbackDismissed(true);
                }}
                isSubmitting={rateSession.isPending}
                isSuccess={rateSession.isSuccess}
              />
            </Show>
          </>
        )}
      </Show>
    </AppPageContainer>
  );
}

type SessionBacklogProps = {
  sessionId: string;
  isSessionActive: boolean;
  questions: Question[];
};

function SessionBacklog(props: SessionBacklogProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        textStyle="xsmallImportant"
        tag="h2"
        class={css({
          color: "lightGray",
          fontSize: "1.5rem",
          mb: "10",
        })}
      >
        {t("session.backlog.title")}
      </Typography>
      <VStack w="full" gap="8">
        <For
          each={props.questions}
          fallback={<Typography>{t("session.backlog.empty")}</Typography>}
        >
          {(item) => (
            <QuestionController
              sessionId={props.sessionId}
              question={item}
              showControls={props.isSessionActive}
            />
          )}
        </For>
      </VStack>
    </>
  );
}

type QuestionControllerProps = {
  sessionId: string;
  question: Question;
  showControls: boolean;
};

function QuestionController(props: QuestionControllerProps) {
  const { t } = useTranslation();
  const { skip, focus } = useQuestionActions(
    () => props.sessionId,
    () => props.question.id,
  );

  return (
    <VStack w="full" p="4" backgroundColor="darkGray" borderRadius="lg">
      <HStack w="full" justifyContent="space-between" alignItems="center">
        <Typography
          textStyle="xsmall"
          class={css({
            color: "lightGray",
          })}
        >
          {t("session.question.from", {
            username: props.question.twitchUsername,
          })}
        </Typography>
        <Typography
          textStyle="xsmall"
          class={css({
            color: "lightGray",
          })}
        >
          {getTimeAgo(props.question.insertedAt)}
        </Typography>
      </HStack>

      <HStack w="full" justifyContent="flex-start" alignItems="flex-start">
        <Typography
          class={css({
            color: "white",
            flexGrow: "3",
          })}
        >
          {props.question.question}
        </Typography>

        <Show when={props.showControls}>
          <Button
            tag="button"
            ctx="dark"
            onClick={() => focus.mutate()}
            status="primary"
          >
            {t("session.question.focus")}
          </Button>

          <Button
            tag="button"
            ctx="dark"
            onClick={() => skip.mutate()}
            status="secondary"
          >
            {t("session.question.skip")}
          </Button>
        </Show>
      </HStack>
    </VStack>
  );
}

type SessionHeaderProps = {
  status: SocketStatus;
  session: Session;
  userId: string;
  onEndSession: () => void;
};

const SessionHeader = (props: SessionHeaderProps) => {
  const { t, locale } = useTranslation();

  const overlayLink = () =>
    `${config.APP_URL}/${locale}/overlay/${props.userId}`;

  function onCopyLink() {
    window.navigator.clipboard.writeText(overlayLink());
  }

  return (
    <styled.header
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      w="full"
      gap="2"
      py="4"
      lg={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: "6",
      }}
    >
      <Typography
        tag="h1"
        textStyle="xsmall"
        class={css({
          color: "yellow",
        })}
      >
        {props.session.name}
      </Typography>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="2"
        flex="1"
        lg={{
          alignItems: "flex-end",
        }}
      >
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="2"
          lg={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HStack>
            <Show
              when={props.status.twitch}
              fallback={
                <>
                  <Typography
                    textStyle="xsmall"
                    class={css({ color: "white" })}
                  >
                    {t("session.header.disconnected", { service: "Twitch" })}
                  </Typography>
                  <Circle class={css({ fill: "red.900" })} />
                </>
              }
            >
              <Typography textStyle="xsmall" class={css({ color: "white" })}>
                {t("session.header.connected", { service: "Twitch" })}
              </Typography>
              <Circle class={css({ fill: "green.900", w: "10", h: "10" })} />
            </Show>
          </HStack>

          <Show when={props.session.doneAt == null}>
            <Button
              tag="button"
              color="yellow"
              ctx="dark"
              status="secondary"
              onClick={() => props.onEndSession()}
            >
              {t("session.header.endSession")}
            </Button>
          </Show>
        </Flex>

        <Show when={props.session.doneAt == null}>
          <HStack>
            <InputField value={overlayLink()} disabled type="text" />
            <Button tag="button" color="yellow" ctx="dark" onClick={onCopyLink}>
              {t("session.header.copyLink")}
            </Button>
          </HStack>
        </Show>
      </Flex>
    </styled.header>
  );
};

type ActiveQuestionControllerProps = {
  session: ReturnType<typeof useSession>["session"]["data"];
  questions: ReturnType<typeof useSessionQuestions>["data"];
  onNextQuestion: ReturnType<typeof useSession>["nextQuestion"];
  onClearQuestion: ReturnType<typeof useSession>["clearQuestion"];
};

function ActiveQuestionController(props: ActiveQuestionControllerProps) {
  const { t } = useTranslation();
  const isQuestionFocused = () => Boolean(props.questions.focused);
  const isQuestionListEmpty = () => props.questions.list.length === 0;

  return (
    <Show when={props.session?.doneAt == null}>
      <VStack
        w="full"
        backgroundColor="darkGray"
        p="10"
        gap="8"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography
          tag="h2"
          textStyle="body"
          class={css({ color: "lightGray" })}
        >
          {t("session.activeQuestion.title")}
        </Typography>

        <Switch>
          <Match when={props.questions.focused}>
            {(question) => (
              <VStack w="full" backgroundColor="yellow" p="6" borderRadius="lg">
                <HStack
                  w="full"
                  justifyContent="space-between"
                  alignItems="center"
                  color="black"
                >
                  <Typography textStyle="xsmall">
                    {t("session.activeQuestion.from", {
                      username: question().twitchUsername,
                    })}
                  </Typography>
                  <Typography textStyle="xsmall">
                    {getTimeAgo(question().insertedAt)}
                  </Typography>
                </HStack>

                <Flex
                  flexDirection="column"
                  w="full"
                  justifyContent="flex-start"
                  alignItems="flex-end"
                  lg={{
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    textStyle="heading3"
                    class={css({
                      w: "full",
                      flexGrow: "3",
                      pb: "10",
                      color: "black",
                    })}
                  >
                    {question().question}
                  </Typography>

                  <HStack
                    justifyContent="flex-end"
                    alignItems="center"
                    w="full"
                    gap="4"
                    flexGrow="1"
                  >
                    <Button
                      tag="button"
                      color="darkGray"
                      size="lg"
                      ctx="dark"
                      onClick={() => props.onNextQuestion.mutateAsync()}
                    >
                      {t("session.activeQuestion.nextQuestion")}
                    </Button>

                    <Button
                      tag="button"
                      color="darkGray"
                      status="secondary"
                      size="lg"
                      onClick={() => props.onClearQuestion.mutateAsync()}
                    >
                      {t("session.activeQuestion.clearOverlay")}
                    </Button>
                  </HStack>
                </Flex>
              </VStack>
            )}
          </Match>

          <Match when={!isQuestionFocused() && !isQuestionListEmpty()}>
            <VStack
              w="full"
              justifyContent="center"
              alignItems="center"
              gap="10"
            >
              <Typography class={css({ color: "white" })}>
                {t("session.activeQuestion.pendingQuestions")}
              </Typography>
              <Button
                tag="button"
                size="lg"
                ctx="dark"
                onClick={() => props.onNextQuestion.mutateAsync()}
              >
                {t("session.activeQuestion.nextQuestion")}
              </Button>
            </VStack>
          </Match>

          <Match when={!isQuestionFocused() && isQuestionListEmpty()}>
            <VStack
              w="full"
              justifyContent="center"
              alignItems="center"
              gap="10"
            >
              <Typography
                class={css({
                  color: "white",
                })}
              >
                {t("session.activeQuestion.noQuestions")}
              </Typography>
            </VStack>
          </Match>
        </Switch>
      </VStack>
    </Show>
  );
}
