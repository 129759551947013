import { Index, createMemo } from "solid-js";

import StarIcon from "#lucide-solid/icons/star";
import { styled, HStack } from "#style/jsx";
import { cva } from "#style/css";

import { Field } from "./Field";

const MAX_STARS = 5;
const STARS_ARRAY = Array.from({ length: MAX_STARS });

const starStyle = cva({
  base: {
    cursor: "pointer",
  },
  variants: {
    status: {
      active: {
        fill: "yellow",
        stroke: "yellow",
      },
      inactive: {
        fill: "transparent",
        stroke: "lightGray",
      },
    },
  },
});

export interface RatingFieldProps {
  name: string;
  label?: string;
  onChange?: (value: number) => void;
  value?: number;
}

export function RatingField(props: RatingFieldProps) {
  return (
    <Field.Container>
      <Field.Label>{props.label}</Field.Label>

      <HStack>
        <Index each={STARS_ARRAY}>
          {(_, index) => {
            const starValue = createMemo(() => index + 1);
            const starId = createMemo(() => `rate_${starValue()}`);

            return (
              <Field.Label for={starId()}>
                <Field.AccessibilityLabel>
                  Rate {starValue()} out of {MAX_STARS}
                </Field.AccessibilityLabel>
                <StarIcon
                  class={starStyle({
                    status:
                      props.value && props.value >= starValue()
                        ? "active"
                        : "inactive",
                  })}
                  size={52}
                />
                <styled.input
                  type="radio"
                  id={starId()}
                  name="rating"
                  value={starValue()}
                  width="0"
                  height="0"
                  onChange={() => props.onChange?.(starValue())}
                />
              </Field.Label>
            );
          }}
        </Index>
      </HStack>
    </Field.Container>
  );
}
